$(document).ready(function () {
  //Para abrir categorias de produto no menu horizontal
  $('#horizontal-navbar .navbar-desktop #product-menu').on('click', function () {
    $('#horizontal-navbar .list-of-categories').slideDown(1000);
  });
  //Para fechar categorias de produto no menu horizontal
  $('#horizontal-navbar .close-icon').on('click', function () {
    $('#horizontal-navbar .list-of-categories').slideUp(500);
  });

  $('#horizontal-navbar #account-icon-page, #horizontal-navbar #wishlist-page, #horizontal-navbar #shopingbag-page').hover(
    function () {
      $(this).find("path, polygon, circle").attr("stroke", "#FF7700");
    }, function () {
      $(this).find("path, polygon, circle").attr("stroke", "#9a9b9e");
    }
  );
  /*  Para abrir o bloco de login no menu horizontal
      O evento para fechar encontra-se no ficheiro user-login-block.js*/
  $('#horizontal-navbar #account-icon-page, #register-user .anchor-for-login').on('click', function (evt) {
    evt.stopPropagation();
    $('#horizontal-navbar #account-icon-page').find("path, polygon, circle").attr("stroke-width", 1.5);
    $('.login-content').css({'display': 'block'});
    $('.login-content').animate({right: '0'}, 250);


    $(document).click(function(e) {
      e.stopPropagation();
      var container =  $('.login-content');

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0)
      {
        //Para fechar o bloco de login no menu horizontal, quando está fora da div do bloco login
        $('#horizontal-navbar #account-icon-page').find("path, polygon, circle").attr("stroke-width", 0.5);
        $('.login-content').animate({right: '-=380px'}, 250);

        setTimeout(function () {
          $('.login-content').css({'display': 'none'});
        }, 900);
      }
    });
  });

  //Click para página dos produtos favoritos
  $('#horizontal-navbar #wishlist-icon-page').on('click', function () {
    $(this).find("path, polygon, circle").attr("stroke-width", 1.5);
    window.location.href = `${baseUrl}/account#favorites-list`;
  });

  //Click para página do carrinho de compras
  $('#horizontal-navbar #shopingbag-icon-page').on('click', function () {
    $(this).find("path, polygon, circle").attr("stroke-width", 1.5);
  });
});

